// Override Bootstrap's variables before importing

// Colors
$purple: #473BE7;
$primary: $purple;
$body-bg: #ECF0F3;
$body-color: #8D8E90;

// Jumbotron
$jumbotron-bg: white;

// Misc
$border-radius: 0;

// Type
$font-family-sans-serif: 'Inter UI', sans-serif;
$headings-margin-bottom: 0.5em;

// Then import Bootstrap
@import "node_modules/bootstrap/scss/bootstrap";
