@import "bootstrap";
@import "components/sidebar";
@import "components/recipes";
@import "components/loading";
@import "components/header";
@import "components/footer";

//
//@media (min-width: 768px) {
//body {
//  padding-top: 3.5em;
//}
//}
//@media (min-width: 400px) {
//  body {
//    padding-top: 10em;
//  }
//}



/**
 * Form Overrides
 */
.form-control {
  &:focus {
    outline: none !important;
    box-shadow: none;
    border-color: $gray-400;
    border-bottom-color: $primary;
  }
}

.btn {
  i {
    font-size: 0.8rem;
  }
}

/**
 * Jumbotron
 */
.jumbotron {
  border-radius: 0;
}

/**
 * Pulsating Loading Animation
 */
.content-loading {
  opacity: 1;
  animation: fadeInOut 1s linear;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0.2 }
  50% { opacity: 0.7 }
}


.svg-map {
  width: 100%;
  height: 380px;
  stroke: #000000;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round; }

.svg-map__location--heat-low {
  fill: #00a84d;
  color: #00a84d;
}
.svg-map__location--heat-medium {
  fill: #d9ce30;
  color: #d9ce30;
  //color: #d99527;
}
.svg-map__location--heat-high {
  fill: #d9650c;
  color: #d9650c;
}
.svg-map__location--heat-very-high {
  fill: #c3211a;
  color: #c3211a;
}
.svg-map__location--heat-pandemic {
  fill: #9b18bf;
  color: #9b18bf;
}
.svg-map__location--heat-no-data {
  fill: #868686;
  color: #868686;
}

.svg-map__location {
  cursor: pointer;
  opacity: 1.0;
  filter: alpha(opacity=100);
}
.svg-map__location:focus, .svg-map__location:hover {
  opacity: 0.7;
  filter: alpha(opacity=70);
  outline: 0;
}
.svg-map__location[aria-checked=true] {
  //opacity: 0.3;
  //filter: alpha(opacity=30);
  //outline: #22262E;
  fill: #fff4f3;
}

.map_tooltip {
  position: fixed;
  width: 200px;
  padding: 10px;
  border: 1px solid darkgray;
  background-color: white;
}



.justifyText{
  text-align: justify;
}


.paper {
  padding: 16px;
  background-color: white;
}

a.no-link h5{
  color: gray;
  text-decoration: none;
}

.client-logo-container img{
  filter: gray; /* IE5+ */
  -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
  -webkit-transition: all .2s ease-in-out;
}
.client-logo-container img:hover {
  filter: none;
  -webkit-filter: grayscale(0);
  -webkit-transform: scale(1.01);
}

.section{
  padding: 30px 40px 30px 50px; // up, right, bottom, left
  margin-left: -50px;
  margin-right: -40px;
}
.section > h1{
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
}





//#chart-section{
//  background-color: rgba(0, 0, 0, 0.7);
//}
//#map-section{
//  background-color: rgba(0, 0, 0, 0.56);
//}
//#methodology-section{
//  background-color: rgba(0, 0, 0, 0.7);
//}
//#about-section{
//  background-color: rgba(0, 0, 0, 0.56);
//}
//#contact-section{
//  background-color: rgba(0, 0, 0, 0.7);
//}


